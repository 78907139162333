<template>
    <el-dialog
    v-model="dialogVisible"
    title="环境风险评估"
    width="600px"
    >
    <el-form
    ref="form"
    :model="ruleForm"
    label-width="100px"
    >
        <el-form-item 
        label="评估取名" 
        prop="title"
        :rules="{ required: true, message: '评估取名不能为空', trigger: 'blur' }">
            <el-input v-model="ruleForm.title" />
        </el-form-item>
        <div class="formItem" v-for="(item, index) in ruleForm.datas" :key="index" >
            <el-form-item 
            :rules="{ required: true, message: '请选择监测指标', trigger: 'blur' }"
            label="上传数据" 
            :prop="'datas.' + index + '.sener'">
                <el-select v-model="item.sener" placeholder="请选择监测指标">
                    <el-option v-for="(item, index) in senerS" :key="index" :label="item.label" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item
            :rules="{ required: true, message: '请上传数据文件', trigger: 'blur' }"
            class="upload-demo" 
            label=""  
            label-width="0" 
            :key="index"  
            :prop="'datas.' + index + '.file'">
                <el-upload
                    v-model:file-list="item.fileList"
                    accept=".xlsx"
                    action=""
                    :http-request="(event)=>httpRequestFile(event, index)"
                    :before-upload="beforeAvatarUploadFile"
                    :on-remove="(event)=>removeFile(event, index)"
                    :limit="1"
                >
                    <el-button type="primary">上传文件</el-button>
                </el-upload>
            </el-form-item>
            <el-button type="primary" v-if="index == 0" circle @click="addFile" >
                <svg-icon icon-class="plus"/>
            </el-button>
            <el-button type="primary" v-else circle @click="minuFile(index)" >
                <svg-icon icon-class="minus"/>
            </el-button>
        </div>
    </el-form>
    <div class="loadLine">
        <a :href="url('/数据模板.xlsx')" download="数据模板.xlsx">
            数据模板下载 <svg-icon icon-class="downLoad"/>
        </a>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitFun('form')">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { site_relicEval } from '@/api/site-warning.js'
import { mapGetters } from 'vuex'
export default{
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            dialogVisible:false,
            fileList:[],
            ruleForm:{
                relicid:null,
                siteid:null,
                title:null,
                datas:[
                    {
                        sener:null,
                        fileList:[],
                        file:null,
                    }
                ]
            },
            senerS:[
                {label:'湿度',id:'01'},
                {label:'温度',id:'02'},
                {label:'二氧化碳',id:'03'},
                {label:'光照度',id:'04'},
                {label:'紫外辐射强度',id:'05'},
                {label:'有机挥发物总量VOC（异丁烯）',id:'06'},
                {label:'有机污染物',id:'07'},
                {label:'无机污染物',id:'08'},
                {label:'含硫污染物',id:'09'},
            ],
        }
    },
    methods:{
        open(relicid){
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.form.resetFields()
                this.ruleForm.relicid = relicid
                this.ruleForm.siteid = this.siteId
                this.ruleForm.datas = [
                    {
                        sener:null,
                        fileList:[],
                        file:null,
                    }
                ]
            })
        },
        addFile(){
            this.ruleForm.datas.push({
                sener:null,
                fileList:[],
                file:null,
            })
        },
        minuFile(index){
            this.ruleForm.datas.splice(index, 1);
        },
        httpRequestFile(param,index){
            this.ruleForm.datas[index].file = param.file
            this.ruleForm.datas[index].fileList = [param.file]
        },
        beforeAvatarUploadFile(rawFile){
            if (rawFile.size / 1024 / 1024 > 10) {
                ElMessage.error('文件大小不得超过10M!')
                return false
            }
            return true
        },
        removeFile(param,index){
            this.ruleForm.datas[index].file = null
            this.ruleForm.datas[index].fileList = []
        },
        url(val){
            const ipAddress = window.location.href
            const urlObject = new URL(ipAddress)
            const baseUrl = urlObject.origin 
            return baseUrl + val
        },
        submitFun(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let senerList = this.ruleForm.datas.map(i=>{
                        return i.sener
                    })
                    let senerListNew = new Set(senerList)
                    senerListNew = [...senerListNew]
                    if(senerList.length != senerListNew.length){
                        this.$message.warning("请检查监测指标是否重复!")
                        return
                    }
                    
                    let formData = new FormData()
                    for(let key in this.ruleForm){
                        if(key != 'datas'){
                            formData.append(key,this.ruleForm[key])
                        }else{
                           let type = this.ruleForm[key].map((i)=> {return i.sener}).join(',')
                           this.ruleForm[key].forEach(el => {
                            formData.append('files',el.file)
                           });
                           formData.append('type',type)
                           
                        }
                        
                    }
                    site_relicEval(formData).then(r=>{
                        if(r.code == 200){
                            let routeUrl = this.$router.resolve({
                                'name':'wres'
                            })
                            window.open(`${routeUrl.href}?idPG=${r.data.id}`, '_blank')
                        }
                        this.dialogVisible = false
                    })
                    
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.loadLine{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 50px;
}
.formItem{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}
.upload-demo{
    margin: 0px 10px;
}
</style>